<template>
  <div>
    <phlix-navbar></phlix-navbar>

    <div style="margin-left: 1rem; margin-right: 1rem;">
      <h1 class="title">Signing in to TMDb...</h1>
      <p class="subtitle is-6">Please wait while you are signed in</p>
      <div style="display: flex; justify-content: center">
        <a href="/" class="button is-primary has-text-centered">Home</a>
      </div>
      <p
        v-show="error"
        style="margin: auto;display: flex; justify-content: center; margin-top: 6rem;"
      >
        Unfortunetly there was an error trying to sign in.
        <a
          style="margin-left: 3px;"
          href="/"
        >Back to home</a>
      </p>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import TMDb from "@/utils/TMDb.js";
import axios from "axios";
import { Bus } from "@/utils/Bus.js";

const tmdbapi = new TMDb();
Window.tmdb = tmdbapi;

export default {
  name: "signin",
  data() {
    return {
      error: false
    };
  },
  methods: {
    completeSignin() {
      if (tmdbapi.getAccountId() == null || tmdbapi.getAccessToken() == null) {
        let reqtoken = localStorage.getItem("request_token");
        const tmdb = new TMDb();
        tmdb
          .generateAccessToken(reqtoken)
          .then(token => {
            tmdb.storeData(token.data.account_id, token.data.access_token);
            tmdb.convertSession();
            Bus.$emit("signin");
            // console.log("Access token: " + token.data.access_token);
            // console.log("Account id: " + token.data.account_id);
            // const userapi = axios.create({
            //   baseURL: "https://api.themoviedb.org/4/",
            //   headers: {
            //     Authorization: "Bearer " + token.data.access_token,
            //     "Content-Type": "application/json;charset=utf-8"
            //   }
            // });

            // tmdb.getWatchlist(userapi, token.data.account_id).then(list => {
            //   console.log(list);
            // });
          })
          .catch(e => {
            this.error = true;
          });
      }
    }
  },
  created() {
    console.log('Signing in...')
    this.completeSignin();
  },
  components: {
    "phlix-navbar": Navbar
  }
};
</script>

<style scoped>
</style>


